import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import './App.scss';
import axios from "axios";
import {type} from "os";
import Loading from "./Loading";

type raceKeys = 'Caucasian' | 'AfricanAmerican' | 'Hispanic' | 'Asian' | 'Other' | null
type diagKeys = 'circulatory' | 'breath' | 'digestive' | 'diabetes' | 'symptoms' | 'poison' | 'genitourinary' | 'musculoskeletal' | 'tumor' | 'incretion' | 'infection' | 'skin' | 'psychopathy' | 'injury' | 'blood' | 'nerve' | 'puerperium' | 'sensorial' | 'inborn' | null
interface IObjKeys {
  [key: string]: string | number | null;
}
interface xType extends IObjKeys{
  race: raceKeys,
  gender: 0 | 1 | null,
  age: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null,
  time_in_hospital: number | null,
  num_lab_procedures: number | null,
  num_procedures: number | null,
  number_outpatient: number | null,
  number_emergency: number | null,
  number_inpatient: number | null,
  diag_1: diagKeys,
  diag_2: diagKeys,
  diag_3: diagKeys,
  number_diagnoses: number | null,
  max_glu_serum: 'None' | 'Norm' | '>200' | '>300' | '200' | '300' | null,
  A1Cresult: 'None' | '>8' | 'Norm' | '>7' | '8' | '7' | null,
  metformin: 'No' | 'Steady' | 'Up' | 'Down' | null,
  repaglinide: 'No' | 'Steady' | 'Up' | 'Down' | null,
  nateglinide: 'No' | 'Steady' | 'Up' | 'Down' | null,
  chlorpropamide: 'No' | 'Steady' | 'Up' | 'Down' | null,
  glimepiride: 'No' | 'Steady' | 'Up' | 'Down' | null,
  glipizide: 'No' | 'Steady' | 'Up' | 'Down' | null,
  glyburide: 'No' | 'Steady' | 'Up' | 'Down' | null,
  pioglitazone: 'No' | 'Steady' | 'Up' | 'Down' | null,
  rosiglitazone: 'No' | 'Steady' | 'Up' | 'Down' | null,
  acarbose: 'No' | 'Steady' | 'Up' | 'Down' | null,
  miglitol: 'No' | 'Steady' | 'Up' | 'Down' | null,
  insulin: 'No' | 'Steady' | 'Up' | 'Down' | null,
  glyburideMetformin: 'No' | 'Steady' | 'Up' | 'Down' | null,
  change: 0 | 1 | null,
  diabetesMed: 0 | 1 | null
}

const App = () => {
  // const serverUrl = 'http://localhost:5025'
  const serverUrl = 'https://api-diabetes.intelloper.com'

  const [data, setData] = useState<xType>({
    race: 'Caucasian',
    gender: 0,
    age: 7,
    time_in_hospital: 9,
    num_lab_procedures: 25,
    num_procedures: 3,
    number_outpatient: 0,
    number_emergency: 0,
    number_inpatient: 2,
    diag_1: 'circulatory',
    diag_2: 'circulatory',
    diag_3: 'diabetes',
    number_diagnoses: 7,
    max_glu_serum: 'None',
    A1Cresult: 'None',
    metformin: 'No',
    repaglinide: 'No',
    nateglinide: 'No',
    chlorpropamide: 'No',
    glimepiride: 'No',
    glipizide: 'No',
    glyburide: 'Steady',
    pioglitazone: 'No',
    rosiglitazone: 'No',
    acarbose: 'No',
    miglitol: 'No',
    insulin: 'Down',
    glyburideMetformin: 'No',
    change: 1,
    diabetesMed: 1,
  })
  const [predictResult, setPredictResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputItems = [
    {name: 'race', id: "race", placeholder: ''},
    {name: 'gender', id: "gender", placeholder: ''},
    {name: 'age', id: "age", placeholder: ''},
    {name: 'time_in_hospital', id: "time_in_hospital", placeholder: '1~14'},
    {name: 'num_lab_procedures', id: "num_lab_procedures", placeholder: '1~132'},
    {name: 'num_procedures', id: "num_procedures", placeholder: '0~6'},
    {name: 'number_outpatient', id: "number_outpatient", placeholder: '0~42'},
    {name: 'number_emergency', id: "number_emergency", placeholder: '0~76'},
    {name: 'number_inpatient', id: "number_inpatient", placeholder: '0~21'},
    {name: 'diag_1', id: "diag_1", placeholder: ''},
    {name: 'diag_2', id: "diag_2", placeholder: ''},
    {name: 'diag_3', id: "diag_3", placeholder: ''},
    {name: 'number_diagnoses', id: "number_diagnoses", placeholder: '3~16'},
    {name: 'max_glu_serum', id: "max_glu_serum", placeholder: ''},
    {name: 'A1Cresult', id: "A1Cresult", placeholder: ''},
    {name: 'metformin', id: "metformin", placeholder: ''},
    {name: 'repaglinide', id: "repaglinide", placeholder: ''},
    {name: 'nateglinide', id: "nateglinide", placeholder: ''},
    {name: 'chlorpropamide', id: "chlorpropamide", placeholder: ''},
    {name: 'glimepiride', id: "glimepiride", placeholder: ''},
    {name: 'glipizide', id: "glipizide", placeholder: ''},
    {name: 'glyburide', id: "glyburide", placeholder: ''},
    {name: 'pioglitazone', id: "pioglitazone", placeholder: ''},
    {name: 'rosiglitazone', id: "rosiglitazone", placeholder: ''},
    {name: 'acarbose', id: "acarbose", placeholder: ''},
    {name: 'miglitol', id: "miglitol", placeholder: ''},
    {name: 'insulin', id: "insulin", placeholder: ''},
    {name: 'glyburideMetformin', id: "glyburideMetformin", placeholder: ''},
    {name: 'change', id: "change", placeholder: ''},
    {name: 'diabetesMed', id: "diabetesMed", placeholder: ''},
  ]

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id
    let numVal = Number(e.currentTarget.value)
    let strVal = e.currentTarget.value
    switch (id) {
      case "race":
        if (strVal === 'Caucasian' || strVal === 'AfricanAmerican' || strVal === 'Hispanic' || strVal === 'Asian' || strVal === 'Other') {
          const raceClass = document.querySelectorAll<HTMLElement>('.race');
          for (let i = 0; i < raceClass.length; i++) {
            raceClass[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          const race: 'Caucasian' | 'AfricanAmerican' | 'Hispanic' | 'Asian' | 'Other' = strVal
          setData(prevState => ({...prevState, race: race}))
        }
        break
      case "gender":
        strVal === "Female" ? numVal = 0 : numVal = 1
        if (numVal === 0 || numVal === 1) {
          const classes = document.querySelectorAll<HTMLElement>('.gender');
          for (let i = 0; i < classes.length; i++) {
            classes[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          const gender: 0 | 1 = numVal
          setData(prevState => ({...prevState, gender: gender}))
        }
        break
      case "change":
        if (numVal === 0 || numVal === 1) {
          const classes = document.querySelectorAll<HTMLElement>('.change');
          for (let i = 0; i < classes.length; i++) {
            classes[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          const change: 0 | 1 = numVal
          setData(prevState => ({...prevState, change: change}))
        }
        break
      case "diabetesMed":
        if (numVal === 0 || numVal === 1) {
          const classes = document.querySelectorAll<HTMLElement>('.diabetesMed');
          for (let i = 0; i < classes.length; i++) {
            classes[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          const diabetesMed: 0 | 1 = numVal
          setData(prevState => ({...prevState, diabetesMed: diabetesMed}))
        }
        break
      case "age":
        if (0 <= numVal && numVal <= 10) {
          numVal = 0
        } else if (10 < numVal && numVal <= 20) {
          numVal = 1
        } else if (20 < numVal && numVal <= 30) {
          numVal = 2
        } else if (30 < numVal && numVal <= 40) {
          numVal = 3
        } else if (40 < numVal && numVal <= 50) {
          numVal = 4
        } else if (50 < numVal && numVal <= 60) {
          numVal = 5
        } else if (60 < numVal && numVal <= 70) {
          numVal = 6
        } else if (70 < numVal && numVal <= 80) {
          numVal = 7
        } else if (80 < numVal && numVal <= 90) {
          numVal = 8
        } else if (90 < numVal && numVal <= 100) {
          numVal = 9
        }

        if (numVal === 0 || numVal === 1 || numVal === 2 || numVal === 3 || numVal === 4 ||
          numVal === 5 || numVal === 6 || numVal === 7 || numVal === 8 || numVal === 9
        ) {
          const num: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = numVal
          setData(prevState => ({...prevState, age: num}))
        }
        break
      case "time_in_hospital":
      case "num_lab_procedures":
      case "num_procedures":
      case "number_outpatient":
      case "number_emergency":
      case "number_inpatient":
      case "number_diagnoses":
        setData(prevState => ({...prevState, [id]: numVal}))
        break
      case "max_glu_serum":
        if (strVal === 'None' || strVal === 'Norm' || strVal === '>200' || strVal === '>300') {
          const classes = document.querySelectorAll<HTMLElement>('.max_glu_serum');
          for (let i = 0; i < classes.length; i++) {
            classes[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          let max_glu_serum: 'None' | 'Norm' | '>200' | '>300' | '200' | '300' = strVal

          if (max_glu_serum === '>300') {
            max_glu_serum = '300'
          } else if (max_glu_serum === '>200') {
            max_glu_serum = '200'
          }
          setData(prevState => ({...prevState, max_glu_serum: max_glu_serum}))
        }
        break
      case "A1Cresult":
        if (strVal === 'None' || strVal === '>8' || strVal === 'Norm' || strVal === '>7') {
          const classes = document.querySelectorAll<HTMLElement>('.A1Cresult');
          for (let i = 0; i < classes.length; i++) {
            classes[i].style.backgroundColor = 'white'
          }
          e.currentTarget.style.backgroundColor = '#cacaca'
          let A1Cresult: 'None' | '>8' | 'Norm' | '>7' | '8' | '7' = strVal
          if (A1Cresult === '>8') {
            A1Cresult = '8'
          } else if (A1Cresult === '>7') {
            A1Cresult = '7'
          }
          setData(prevState => ({...prevState, A1Cresult: A1Cresult}))
        }
        break
      case "metformin":
      case "repaglinide":
      case "nateglinide":
      case "chlorpropamide":
      case "glimepiride":
      case "glipizide":
      case "glyburide":
      case "pioglitazone":
      case "rosiglitazone":
      case "acarbose":
      case "miglitol":
      case "insulin":
      case "glyburideMetformin":
        const classes = document.querySelectorAll<HTMLElement>('.' + id);
        for (let i = 0; i < classes.length; i++) {
          classes[i].style.backgroundColor = 'white'
        }
        e.currentTarget.style.backgroundColor = '#cacaca'
        setData(prevState => ({...prevState, [id]: strVal}))
        break
      default:
        setData(prevState => ({...prevState, [id]: numVal}))
    }
  }

  const handleFloatNum = (e: FormEvent<HTMLInputElement>, id: string) => {
    /* 숫자 3자리 이상 누르면 자동으로 키에 맞게 소숫점을 붙여주는 로직 */
    let textHeight = e.currentTarget.value.toString()
    let firstNums
    let secondNums
    let floatNum: number | undefined
    /* input value의 마지막 값이 '.' 일때 처리  */
    if (textHeight.substr(textHeight.length - 1, 1) === ".") {
      textHeight = textHeight.substr(0, textHeight.length - 1)
    }

    /* input value length가 5보다 클때 */
    if (textHeight.length > 5) {
      firstNums = textHeight.substr(0, 3)
      secondNums = textHeight.substr(4, 1)
      floatNum = parseFloat(firstNums + "." + secondNums)
      /* input value length가 4보다 클때 */
    } else if (textHeight.length > 4) {
      firstNums = textHeight.substr(0, 2)
      secondNums = textHeight.substr(3, 1)
      let thirdNums = textHeight.substr(4, 1)
      floatNum = parseFloat(firstNums + secondNums + "." + thirdNums)
      /* input value length가 4와 같고 3번째 자리 숫자가 0일때 */
    } else if (textHeight.length === 4 && textHeight.substr(2, 1) === "0") {
      firstNums = textHeight.substr(0, 3)
      secondNums = textHeight.substr(3, 1)
      floatNum = parseFloat(firstNums + "." + secondNums)
      /* input value length가 3이하 일때 */
    } else if (textHeight.length <= 3) {
      firstNums = textHeight.substr(0, 2)
      secondNums = textHeight.substr(2, 1)

      /* 몸무게의 경우 */
      if (id === "babyWeight") {
        /* input value 처음 2자리가 10보다 크고 세번째 숫자가 0일때 */
        if (parseInt(firstNums) > 10 && secondNums === "0") {
          floatNum = parseFloat(firstNums + "." + secondNums)
          /* input value 처음 2자리가 10보다 크고 세번째 숫자가 0이 아닐때 */
        } else if (parseInt(firstNums) > 10 && textHeight.substr(2, 1) !== "0") {
          floatNum = parseFloat(firstNums + "." + secondNums)
        } else {
          floatNum = parseFloat(firstNums + secondNums)
        }
      } else {
        /* input value 처음 2자리가 25보다 크고 세번째 숫자가 0일때 */
        if (parseInt(firstNums) > 25 && secondNums === "0") {
          floatNum = parseFloat(firstNums + "." + secondNums)
          /* input value 처음 2자리가 25보다 크고 세번째 숫자가 0이 아닐때 */
        } else if (parseInt(firstNums) > 25 && textHeight.substr(2, 1) !== "0") {
          floatNum = parseFloat(firstNums + "." + secondNums)
        } else {
          floatNum = parseFloat(firstNums + secondNums)
        }
      }

      /* 숫자가 NaN일때 에러 처리 */
      floatNum.toString() === "NaN" && (e.currentTarget.value = "")
      /* input value가 300보다 클때 */
    } else if (parseInt(textHeight) > 300) {
      firstNums = textHeight.substr(0, 3)
      secondNums = textHeight.substr(3, 1)
      floatNum = parseFloat(firstNums + "." + secondNums)
    }
    /* 마지막 결과 값이 NaN과 undefined 아닐때 */
    // @ts-ignore
    if (floatNum.toString() !== "NaN") {
      // @ts-ignore
      e.currentTarget.value = floatNum.toString()
      // @ts-ignore
      setData({...data, [id]: floatNum})
    }
  }


  /* 입력값 리셋 */
  const clear = () => {
    const raceClass = document.querySelectorAll<HTMLElement>('.race');
    for (let i = 0; i < raceClass.length; i++) {
      raceClass[i].style.backgroundColor = 'white'
    }

    const classes = document.querySelectorAll<HTMLElement>('.inputButton');
    for (let i = 0; i < classes.length; i++) {
      classes[i].style.backgroundColor = 'white'
    }

    document.querySelectorAll<HTMLInputElement>('#age')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#time_in_hospital')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#num_lab_procedures')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#num_procedures')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#number_outpatient')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#number_emergency')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#number_inpatient')[0].value = '';
    document.querySelectorAll<HTMLInputElement>('#number_diagnoses')[0].value = '';

    setData(prevState => ({
      ...prevState,
      race: null,
      gender: null,
      age: null,
      time_in_hospital: null,
      num_lab_procedures: null,
      num_procedures: null,
      number_outpatient: null,
      number_emergency: null,
      number_inpatient: null,
      diag_1: null,
      diag_2: null,
      diag_3: null,
      number_diagnoses: null,
      max_glu_serum: null,
      A1Cresult: null,
      metformin: null,
      repaglinide: null,
      nateglinide: null,
      chlorpropamide: null,
      glimepiride: null,
      glipizide: null,
      glyburide: null,
      pioglitazone: null,
      rosiglitazone: null,
      acarbose: null,
      miglitol: null,
      insulin: null,
      glyburideMetformin: null,
      change: null,
      diabetesMed: null
    }))


    // if (document.getElementById("gender")) document.getElementById("gender").style.backgroundColor = "transparent"
    // if (document.getElementById("genderF")) document.getElementById("genderF").style.backgroundColor = "transparent"
    // const stateInputVals = document.getElementsByClassName('stateInput')
    // for (let i = 0; i < stateInputVals.length; i++) {
    //   (stateInputVals[i] as HTMLInputElement).value = ""
    // }
    // setData({nickName: null})
  }

  const raceVals = ['Caucasian', 'AfricanAmerican', 'Hispanic', 'Asian', 'Other']
  const binaryVals = [0, 1]
  const diagVals = ['', 'circulatory', 'breath', 'digestive', 'diabetes', 'symptoms', 'poison', 'genitourinary', 'musculoskeletal', 'tumor', 'incretion', 'infection', 'skin', 'psychopathy', 'injury', 'blood', 'nerve', 'puerperium', 'sensorial', 'inborn']
  const maxGluSerumVals = ['None', 'Norm', '>200', '>300']
  const A1CresultVals = ['None', '>8', 'Norm', '>7']
  const medicineVals = ['No', 'Steady', 'Up', 'Down']
  const diags = ['diag_1', 'diag_2', 'diag_3']
  const genderVals = ['Female', 'Male']

  const diagValMap = diagVals.map((data, key) => {
    return (<option value={data} key={key}>{data}</option>)
  })

  const handleDiag = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.id
    const val = e.currentTarget.value
    setData(prevState => ({...prevState, [id]: val}))
  }

  const inputItemsComponent = inputItems.map((res, index) => {
    return (
      <div className="infoInputBox" key={index}>
        <div className="inputStateBar">
          <div className="inputStateName">{res.name} </div>
          <div className="inputStateVal">{data[res.id]} </div>
        </div>

        {res.id === "race" && (
          <div id="buttonInput">
            {raceVals.map((data, key) => {
              return (
                <>
                  <div className="radio" key={key}>
                    <input
                      type="button"
                      className={"race " + data + " "}
                      onClick={handleInput}
                      id="race"
                      value={data}
                    />
                  </div>

                  {raceVals.length - 1 !== key && (<>&nbsp;&nbsp;/&nbsp;&nbsp;</>)}
                </>
              )
            })}
          </div>)}

        {(res.id === "gender" || res.id === "change" || res.id === "diabetesMed") && (
          <div id="buttonInput">
            {
              binaryVals.map((data, key) => {
                return (
                  <>
                    <div className="radio" key={key}>
                      <input
                        type="button"
                        className={res.id + " " + data + " inputButton"}
                        onClick={handleInput}
                        id={res.id}
                        value={res.id === "gender" ? genderVals[data] : data}
                      />
                    </div>

                    {binaryVals.length - 1 !== key && (<>&nbsp;&nbsp;/&nbsp;&nbsp;</>)}
                  </>
                )
              })
            }
          </div>
        )}

        {(res.id === "age" ||
          res.id === "time_in_hospital" ||
          res.id === "num_lab_procedures" ||
          res.id === "num_procedures" ||
          res.id === "number_outpatient" ||
          res.id === "number_emergency" ||
          res.id === "number_inpatient" ||
          res.id === "number_diagnoses") &&
        (<div className="inputStateBox">
          <input
            className="stateInput"
            id={res.id}
            onInput={handleInput}
            placeholder={res.placeholder}
          />
          <span className="inputUnit"></span>
        </div>)}

        {(res.id === "diag_1" || res.id === "diag_2" || res.id === "diag_3") && (
          <div id="buttonInput">
            {
              diags.map((item, key) => {
                return (
                  res.id === item && (
                    <select
                      key={key}
                      id={item}
                      onChange={handleDiag}
                    >
                      {diagValMap}
                    </select>
                  )
                )
              })
            }

          </div>
        )}
        {(res.id === "max_glu_serum") && (
          <div id="buttonInput">
            {
              maxGluSerumVals.map((data, key) => {
                return (
                  <>
                    <div className="radio" key={key}>
                      <input
                        type="button"
                        className={res.id + " inputButton"}
                        onClick={handleInput}
                        id="max_glu_serum"
                        value={data}
                      />
                    </div>

                    {maxGluSerumVals.length - 1 !== key && (<>&nbsp;&nbsp;/&nbsp;&nbsp;</>)}
                  </>
                )
              })
            }
          </div>
        )}
        {(res.id === "A1Cresult") && (
          <div id="buttonInput">
            {
              A1CresultVals.map((data, key) => {
                return (
                  <>
                    <div className="radio" key={key}>
                      <input
                        type="button"
                        className={data + " A1Cresult inputButton"}
                        onClick={handleInput}
                        id="A1Cresult"
                        value={data}
                      />
                    </div>

                    {A1CresultVals.length - 1 !== key && (<>&nbsp;&nbsp;/&nbsp;&nbsp;</>)}
                  </>
                )
              })
            }
          </div>)}
        {(res.id === "metformin" ||
          res.id === "repaglinide" ||
          res.id === "nateglinide" ||
          res.id === "chlorpropamide" ||
          res.id === "glimepiride" ||
          res.id === "glipizide" ||
          res.id === "glyburide" ||
          res.id === "pioglitazone" ||
          res.id === "rosiglitazone" ||
          res.id === "acarbose" ||
          res.id === "miglitol" ||
          res.id === "insulin" ||
          res.id === "glyburideMetformin"
        ) && (
          <div id="buttonInput">
            {medicineVals.map((data, key) => {
              return (
                <>
                  <div className="radio" key={key}>
                    <input
                      type="button"
                      className={res.id + " inputButton"}
                      onClick={handleInput}
                      id={res.id}
                      value={data}
                    />
                  </div>

                  {medicineVals.length - 1 !== key && (<>&nbsp;&nbsp;/&nbsp;&nbsp;</>)}
                </>
              )
            })
            }
          </div>)}
      </div>
    )
  });

  async function getPredictResult(data: xType) {
    try {
      const response = await axios.post(serverUrl, data);
      const res = response.data[0]
      return res
    } catch (error) {
      console.error(error);
    }
  }

  const doPredict = () => {
    setLoading(true)
    getPredictResult(data).then(res => {
      console.log('res', res);
      setPredictResult(res)
    }).finally(() => {
      setLoading(false)
    })
  }
  const rePredict = () => {
    setPredictResult(null)
  }

  useEffect(() => {
    // race : Caucasian
    // gender : 0
    // age : 7
    // time_in_hospital : 9
    // num_lab_procedures : 25
    // num_procedures : 3
    // number_outpatient : 0
    // number_emergency : 0
    // number_inpatient : 2
    // diag_1 : circulatory
    // diag_2 : circulatory
    // diag_3 : diabetes
    // number_diagnoses : 7
    // max_glu_serum : None
    // A1Cresult : None
    // metformin : No
    // repaglinide : No
    // nateglinide : No
    // chlorpropamide : No
    // glimepiride : No
    // glipizide : No
    // glyburide : Steady
    // pioglitazone : No
    // rosiglitazone : No
    // acarbose : No
    // miglitol : No
    // insulin : Down
    // glyburide-metformin : No
    // change : 1
    // diabetesMed : 1

    console.log("#######################################################");
    console.log('data.race', data.race);
    console.log('data.gender', data.gender);
    console.log('data.age', data.age);
    console.log('data.time_in_hospital', data.time_in_hospital);
    console.log('data.num_lab_procedures', data.num_lab_procedures);
    console.log('data.num_procedures', data.num_procedures);
    console.log('data.number_outpatient', data.number_outpatient);
    console.log('data.number_emergency', data.number_emergency);
    console.log('data.number_inpatient', data.number_inpatient);
    console.log('data.diag_1', data.diag_1);
    console.log('data.diag_2', data.diag_2);
    console.log('data.diag_3', data.diag_3);
    console.log('data.number_diagnoses', data.number_diagnoses);
    console.log('data.max_glu_serum', data.max_glu_serum);
    console.log('data.A1Cresult', data.A1Cresult);
    console.log('data.metformin', data.metformin);
    console.log('data.repaglinide', data.repaglinide);
    console.log('data.nateglinide', data.nateglinide);
    console.log('data.chlorpropamide', data.chlorpropamide);
    console.log('data.glimepiride', data.glimepiride);
    console.log('data.glipizide', data.glipizide);
    console.log('data.glyburide', data.glyburide);
    console.log('data.pioglitazone', data.pioglitazone);
    console.log('data.rosiglitazone', data.rosiglitazone);
    console.log('data.acarbose', data.acarbose);
    console.log('data.miglitol', data.miglitol);
    console.log('data.insulin', data.insulin);
    console.log('data.glyburideMetformin', data.glyburideMetformin);
    console.log('data.change', data.change);
    console.log('data.diabetesMed', data.diabetesMed);
    console.log("#######################################################");
  }, [data])

  return (
    <div className="App">
      <p className="title">DIABETES PATIENT READMIT PREDICT</p>
      {loading ? (<Loading/>) : (
        <div id="GetInfo">
          {predictResult === 1 && (<div className="resultText">이 환자는 30일 이내에 <br/> <span>재방문할</span> 것입니다.</div>)}
          {predictResult === 0 && (<div className="resultText">이 환자는 30일 이내에 <br/> <span>재방문하지 않을</span> 것입니다.</div>)}
          {predictResult === null && (
            <>
              <div className="inputContainer">
                <div id="infoInput">
                  {inputItemsComponent}
                </div>
              </div>
              <div className="inputDone">
                <div className="buttons">
                  <button onClick={clear} className="btnConfirm">
                    Clear
                  </button>
                  <button className="btnConfirm" onClick={doPredict}>
                    Predict
                  </button>
                </div>
              </div>
            </>
          )}
          {predictResult !== null && (
            <button className="btnConfirm" onClick={rePredict}>
              재예측하기
            </button>
          )}
        </div>
      )}

    </div>
  );
}

// AfricanAmerican
// 0
// 7
// 2
// 47
// 0
// 0
// 0
// 0
// circulatory
// circulatory
// genitourinary
// 8
// None
// None
// No
// No
// No
// No
// No
// No
// No
// No
// No
// No
// No
// No
// No
// 0
// 0
// 0
// 0

export default App;
