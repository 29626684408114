import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
// import { css } from "@emotion/core"
// import "../../../styles/module/Loading.scss"
// const loading = css`
//   display: block;
//   margin: 0;
//   border-color: white;
//   padding: 0;
// `
const Loading = () => {
  return (
    <div className="loadingContainer">
      {/*<ClipLoader color={"#ffffff"} loading={true} css={loading} size={150} />*/}
      {/*<RotateLoader color={"#fff"} loading={true} css={loading} size={20} />*/}
      <div className="predicting">predicting...</div>
      <PulseLoader color={"#000000"} loading={true} size={30} />
    </div>
  );
};

export default Loading;
